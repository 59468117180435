/*
// .block-features
*/


$local-circle-size: 4px;
$local-padding-y: 3px;


@mixin block-features-layout($padding, $columns, $gutter) {
    .block-features {
        ul {
            margin: 0 ($gutter / 2 * -1);
        }
        li {
            width: calc(#{100% / $columns} - #{$gutter});
            margin: 0 ($gutter / 2);
        }
    }
    .block-features__body {
        padding: ($padding - $local-padding-y) $padding;
    }
}


.block-features {
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
    }

    li {
        position: relative;
        text-align: left;
        padding: $local-padding-y 0 $local-padding-y ($local-circle-size + 10px);

        &:before {
            position: absolute;
            content: '';
            width: $local-circle-size;
            height: $local-circle-size;
            background: rgba(#000, .2);
            left: 0;
            top: round(11px - $local-circle-size / 2) + $local-padding-y;
        }
    }
}
.block-features__body {
    max-width: 760px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 1px 5px rgba(#000, .08);
}

@include block-features-layout(48px, 2, 32px);


@media (max-width: 767px) {
    .block-features__body {
        max-width: 360px;
    }

    @include block-features-layout(32px, 1, 0px);
}
@media (max-width: 575px) {
    .block-features {
        padding-left: 10px;
        padding-right: 10px;
    }
}
