$local-gutter: 30px;
$local-columns: 4;


@mixin demos-layout($columns, $gutter) {
    .demos__list {
        margin: -($gutter / 2);
    }
    .demos__item {
        width: calc(100% / #{$columns} - #{$gutter});
        margin: ($gutter / 2);
    }
}


.demos {}
.demos__list {
    margin: -($local-gutter / 2);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.demos__item {
    width: calc(100% / #{$local-columns} - #{$local-gutter});
    margin: ($local-gutter / 2);
}

@media (max-width: 1199px) {
    @include demos-layout(4, 16px);
}
@media (max-width: 767px) {
    @include demos-layout(2, 16px);

    .demos__item {
        max-width: 240px;
    }
}
@media (max-width: 440px) {
    @include demos-layout(1, 24px);
}
