@import 'variables';


body {
    background: $body-bg-color;
    backface-visibility: hidden;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: $body-font-color;
}

a {
    &,
    &:hover {
        color: $link-color;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1110px;
    }
}
