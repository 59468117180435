/*
// .copyright
*/


.copyright {
    padding: 40px 0;
    text-align: center;
    font-size: 15px;
}


@media (max-width: 767px) {
    .copyright {
        padding: 32px 0;
    }
}
@media (max-width: 575px) {
    .copyright {
        padding: 28px 0;
    }
}
