$body-bg-color:     #fafafa;
$body-font-color:   #262626;
$theme-color:       #e52727;
$TAN-20:            0.36397;
$TAN-30:            0.57735;
$carousel-dot-size: 8px;
$font-alt-color:    #6c757d;
$link-color:        $theme-color;

$logo-primary-color:   $theme-color;
$logo-secondary-color: #404040;

