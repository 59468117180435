.intro__body {
    display: flex;
    padding: 92px 0 var(--section-gutter);
}
.intro__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 144px;
    text-align: center;
    padding-bottom: 80px;
}
.intro__window {
    width: 480px;
    flex-shrink: 0;
}
.intro__logo {
    margin-bottom: 36px;
}
.intro__excerpt {
    font-size: 17px;
    line-height: 1.75;
}
.intro__buttons {
    margin-top: 48px;
    display: flex;

    > * + * {
        margin-left: 8px;
    }
}


@media (max-width: 1439px) {
    .intro__body {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 72px;
    }
    .intro__content {
        padding-right: 96px;
    }
}
@media (max-width: 1199px) {
    .intro__body {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 64px;
    }
    .intro__content {
        padding-right: 80px;
    }
    .intro__window {
        width: 440px;
    }
}
@media (max-width: 991px) {
    .intro__body {
        padding-top: 72px;
        display: block;
    }
    .intro__content {
        max-width: 440px;
        margin: 0 auto;
        padding: 0 0 80px;
    }
    .intro__excerpt {
        font-size: 16px;
        line-height: 1.75;
    }
    .intro__window {
        max-width: 100%;
        margin: 0 auto;
    }
}
@media (max-width: 575px) {
    .intro__body {
        padding-top: 40px;
    }
    .intro__content {
        max-width: 400px;
        padding: 0 0 52px;
    }
    .intro__logo {
        margin-bottom: 28px;
    }
    .intro__excerpt {
        font-size: 15px;
        line-height: 1.75;
    }
    .intro__buttons {
        margin-top: 36px;
    }
}
