@import 'variables';


.logo {
    display: flex;
}
.logo__part-primary {
    fill: $logo-primary-color;
}
.logo__part-secondary {
    fill: $logo-secondary-color;
}
