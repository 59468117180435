@import 'variables';


.window {
    background: #fff;
    box-shadow: 0 1px 7px rgba(#000, .12);
    display: flex;
}
.window__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-bottom: 640 / 480 * 100%;
}
.window__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}
.window__header {
    padding: 6px 7px;
}
.window__circles {
    position: relative;
    width: 12px;
    height: 12px;
    background: #ebebeb;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px rgba(#000, .05), inset 0 1px 3px rgba(#000, .05);

    &:before,
    &:after {
        display: block;
        position: absolute;
        content: '';
        width: inherit;
        height: inherit;
        border-radius: inherit;
        box-shadow: inherit;
        background: inherit;
    }
    &:before {
        transform: translateX(calc(100% + 6px));
    }
    &:after {
        transform: translateX(calc((100% + 6px) * 2));
    }
}

$local-height: 17px;
$local-padding-skew: $local-height * $TAN-20;

.window__title {
    height: $local-height;
    position: absolute;
    top: -4px;
    right: 8px;
    z-index: 0;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 ($local-padding-skew + 6px);
    color: #fff;
    line-height: 13px;

    &:before {
        position: absolute;
        content: '';
        left: $local-padding-skew / 2;
        top: 0;
        height: 100%;
        width: calc(100% - #{$local-padding-skew});
        z-index: -1;
        border-radius: 2px 2.5px;
        transform: skewX(-20deg);
        background: var(--bg-color);
    }
}
.window__viewport {
    flex-grow: 1;
    padding: 0 5px 5px;
    display: flex;
}
.window__content {
    background: #ebebeb;
    flex-grow: 1;
    position: relative;
}
.window__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    overflow: hidden;

    &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#fff, .4);
        opacity: 0;
        transition: opacity .4s;
    }

    &:hover:after {
        opacity: 1;
    }
}
.window__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: none;
    object-position: top center;

    @media (max-width: 1199px) {
        object-fit: cover;
    }
}


@mixin local-window-button($height, $width, $font-size, $clip: false, $expand: 4px) {
    .window__button-substrate {
        height: calc(100% + #{$expand});
        width: calc(100% + #{$expand + 1px});
    }

    .window__button-body {
        --padding: #{$height * $TAN-30};
        height: $height;
        width: $width;
        font-size: $font-size;
        line-height: $font-size;

        @if ($clip) {
            --padding: #{($height * $TAN-30) / 3 * 2};
            overflow: hidden;
            border-radius: 2px;
        }

        &:after {
            width: calc(100% + #{$height * $TAN-30} + 4px);
        }
    }
}


$local-height: 43px;
$local-expand: 4px;
$local-width: 140px;

.window__button {
    position: absolute;
    bottom: 0;
    z-index: 0;
}
.window__button-substrate {
    position: absolute;
    height: calc(100% + #{$local-expand});
    width: calc(100% + #{$local-expand + 1px});
    overflow: hidden;
    z-index: -1;
    bottom: 0;

    &:before {
        background: #fff;
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        transform-origin: bottom;
    }
}
.window__button-body {
    --padding: #{$local-height * $TAN-30};
    position: relative;
    z-index: 0;
    pointer-events: none;
    color: #fff;
    height: $local-height;
    width: $local-width;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 1.5px;

    span {
        position: relative;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: rgba(#000, .001);
        height: calc(100% + 4px);
        width: calc(100% + #{$local-height * $TAN-30} + 4px);
        transition: background .2s;
    }

    &:before {
        background: #333;
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        transform-origin: bottom;
        border-radius: 3.2px 3px;
        z-index: -1;
        pointer-events: auto;
        transition: background .2s;
    }

    &:hover {
        color: #fff;
        text-decoration: none;

        &:before {
            background: $theme-color;
        }
        &:after {
            background: rgba(#000, .002);
        }
    }
}


.window__button--ltr {
    left: 0;

    .window__button-substrate {
        left: 0;
    }
    .window__button-substrate:before {
        transform: skewX(30deg);
        border-top-right-radius: 3px;
    }
    .window__button-body {
        padding-right: var(--padding);
    }
    .window__button-body:before {
        right: 0;
        transform: skewX(30deg);
    }
}
.window__button--rtl {
    right: 0;

    .window__button-substrate {
        right: 0;
    }
    .window__button-substrate:before {
        transform: skewX(-30deg);
        border-top-left-radius: 3px;
    }
    .window__button-body {
        padding-left: var(--padding);
    }
    .window__button-body:before {
        left: 0;
        transform: skewX(-30deg);
    }
}

.window--intro {
    @media (max-width: 575px) {
        @include local-window-button(33px, 92px, 14px, true, 4px);
    }
}

.window--size--small {
    width: auto;
    height: auto;
    box-shadow: 0 1px 5px rgba(#000, .1);

    @include local-window-button(23px, 64px, 11px, true, 3px);
}
