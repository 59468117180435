@import 'variables';


.button {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    padding: 14px 48px;
    border-radius: 2px;
    background: var(--button--bg-color, transparent);
    color: var(--button--font-color, #262626);
    position: relative;
    transition: background-color .2s;

    &:hover {
        color: var(--button--font-color, #262626);
        text-decoration: none;
    }
}
.button__counter {
    $local-height: 20px;

    height: $local-height;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    z-index: 0;
    padding: 0 12px;
    top: -($local-height / 2 - 2px);
    right: 0;
    transform: translateX(#{($local-height * $TAN-20) / 2 + 8px});

    &:before {
        position: absolute;
        display: block;
        content: '';
        background: #333;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: skewX(-20deg);
        border-radius: 2px 3px;
        z-index: -1;
    }
}
.button--style--primary {
    --button--bg-color: #{$theme-color};
    --button--font-color: #fff;
}
.button--style--primary:hover {
    --button--bg-color: #333;
}
.button--style--primary:active {
    --button--bg-color: #{lighten(#333, 10%)};
}
.button--style--secondary {
    --button--bg-color: #ebebeb;
    --button--font-color: inherit;
}

@media (max-width: 575px) {
    .button {
        font-size: 16px;
        line-height: 20px;
        padding: 11px 36px;
    }
    .button__counter {
        $local-height: 18px;

        height: $local-height;
        font-size: 11px;
        line-height: 13px;
        padding: 0 9px;
        top: -($local-height / 2 - 2px);
        transform: translateX(#{($local-height * $TAN-20) / 2 + 6px});
    }
}
