@import 'variables';


:root {
    --section-gutter: 128px;

    @media (max-width: 1439px) {
        --section-gutter: 116px;
    }
    @media (max-width: 1199px) {
        --section-gutter: 96px;
    }
    @media (max-width: 991px) {
        --section-gutter: 80px;
    }
    @media (max-width: 767px) {
        --section-gutter: 68px;
    }
    @media (max-width: 575px) {
        --section-gutter: 52px;
    }
}


@import 'common';
@import 'copyright';
@import 'intro';
@import 'logo';
@import 'button';
@import 'window';
@import 'demos';
@import 'section';

@import 'block-headers';
@import 'block-features';
@import 'block-image';
