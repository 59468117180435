@import 'variables';


.section {
    text-align: center;

    & + & {
        margin-top: var(--section-gutter);
    }
}
.section__title {
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700;
}
.section__subtitle {
    color: $font-alt-color;
    margin-top: 8px;
    font-size: 15px;
}
.section__header {
    margin-bottom: 44px;
}

@media (max-width: 991px) {
    .section__title {
        font-size: 32px;
    }
    .section__header {
        margin-bottom: 32px;
    }
}
@media (max-width: 767px) {
    .section__header {
        margin-bottom: 28px;
    }
}
@media (max-width: 575px) {
    .section__title {
        font-size: 28px;
    }
}
