@import 'variables';


.block-headers__item {
    & + & {
        margin-top: 24px;
    }

    @media (max-width: 575px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.block-headers__carousel {
    margin: 0 -10px;
    width: auto;

    .owl-dots {
        margin-top: 8px;
        display: flex;
        justify-content: center;
    }
    .owl-dot:focus {
        outline: none;
    }
    .owl-dot:before {
        display: block;
        content: '';
        width: $carousel-dot-size;
        height: $carousel-dot-size;
        border-radius: $carousel-dot-size / 2;
        background: rgba(#000, .1);
        margin: 6px;
    }
    .owl-dot:not(.active):hover:before {
        background: rgba(#000, .15);
    }
    .owl-dot:not(.active):active:before {
        background: rgba(#000, .2);
    }
    .owl-dot.active:before {
        background: $theme-color;
    }
}
.block-headers__carousel-item {
    img {
        max-width: 100%;
        height: 100%;
    }
}
